import React, { useEffect } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { InputComponent } from "../uiParts/inputFields/common";
import { BirthDateField } from "../uiParts/inputFields/birthDateField";
import { SexSelection } from "../uiParts/inputFields/sexSelection";
import { useFormikContext } from "formik";
import { useSetRecoilState } from "recoil";
import { tabErrorState } from "../../states/tabErrorState";
import { get as _get, has as _has } from "lodash";
import { OcrButton } from "../uiParts/inputFields/ocrInput";

export const PatientInfoSection = () => {
  const { errors, touched } = useFormikContext();
  const setPatientInfoErrorState = useSetRecoilState(
    tabErrorState("patientInfo")
  );
  const fieldNames = [
    "patientName",
    "patientBirthDate",
    "patientSex",
    "patientAddress",
    "patientPhoneNumber",
  ];

  useEffect(() => {
    let errorFields: string[] = [];
    fieldNames.map((fn) => {
      if (_has(errors, fn) && _get(errors, fn) && _get(touched, fn)) {
        errorFields.push(fn);
      }
    });
    setPatientInfoErrorState(errorFields);
  }, [errors, touched]);

  return (
    <SimpleGrid columns={6} spacing={2} rounded="md">
      <OcrButton ocrType="license" />

      <InputComponent name="patientName" label="氏名" isRequired={false} />

      <BirthDateField name="patientBirthDate" />

      <SexSelection name="patientSex" />

      <InputComponent name="patientAddress" label="住所" />

      <InputComponent name="patientPhoneNumber" label="電話番号" />
    </SimpleGrid>
  );
};
