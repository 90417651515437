import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  GridItem,
  FormLabel,
  Input,
  FormControl,
  FormErrorMessage,
  Image,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { useField } from "formik";
import { FiPlus, FiTrash, FiX } from "react-icons/fi";
import {
  atomFamily,
  useRecoilState,
} from "recoil";
import { v4 as uuidv4 } from "uuid";

export const ImageInput = ({
  name,
  label,
  isRequired,
}: {
  name: string;
  label: string;
  isRequired?: boolean;
}) => {
  const [imageState, setImageState] = useRecoilState(imageInputeState(name));
  const [field, meta, helpers] = useField(name);
  const inputId = `${name}_pseudo_form_${Math.random()}`;

  return (
    <FormControl
      as={GridItem}
      colSpan={6}
      py={4}
      isRequired={isRequired ?? false}
      // isInvalid={isInvalid(meta)}
    >
      <FormLabel fontSize="md" htmlFor={name}>
        {label}
      </FormLabel>
      <Input {...field} hidden />

      <Thumbnails name={name} />

      {/** img inputs */}
      <label htmlFor={inputId}>
        <Button as="a" leftIcon={<FiPlus />}>
          画像を追加
        </Button>
        <input
          id={inputId}
          type="file"
          accept="image/*"
          multiple={true}
          hidden
          onChange={(e) => {
            if (e.currentTarget.files) {
              let newImageProps: imageProp[] = imageState;
              for (let i = 0; i < e.currentTarget.files.length; i++) {
                let file = e.currentTarget.files[i];
                newImageProps = [
                  ...newImageProps,
                  {
                    originFileName: file.name,
                    fileId: uuidv4(),
                    file: file,
                  } as imageProp,
                ];
              }
              setImageState(newImageProps);
              // uploadImageS3(file).then(fileName => helpers.setValue([fileName]));
              helpers.setValue(newImageProps.map((imgProp) => imgProp.fileId))
            }
          }}
        />
      </label>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

const Thumbnails = ({ name }: { name: string }) => {
  const [imageState, setImageState] = useRecoilState(imageInputeState(name));
  const dissmissImage = (file:File) => {
    const newImageState = imageState.filter((img) => img.file != file)
    setImageState(newImageState)
  }

  return (
    <>
      {imageState.map((img) => (
        <_Thumbnail key={img.fileId} file={img.file} onDismiss={dissmissImage} />
      ))}
    </>
  );
};

const _Thumbnail = ({ file, onDismiss }: { file: File, onDismiss:(file:File)=>void }) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const reader: FileReader = new FileReader();
  reader.addEventListener("loadend", () => setImgSrc(reader.result as string));
  reader.readAsDataURL(file);

  return (
      imgSrc ?
      <Box m={4} >
        <Flex top={8} right={8} zIndex={10} position="relative">
          <Spacer />
          <Box as={IconButton} aria-label="discard image" h={12} w={12} bg="#f005" _hover={{bg: "#f008"}}
          onClick={(e)=>onDismiss(file)}
          icon={<FiTrash size={16} />} />
        </Flex>
        <Image  boxShadow='xl' rounded='md' position="relative" top={-8} src={imgSrc} />
      </Box>
      : null
  );
};

export type imageProp = {
  originFileName: string;
  fileId: string;
  file: File;
};

export const imageInputeState = atomFamily<imageProp[], string>({
  key: "iamgeInputState",
  default: [],
});
