import React, { useState } from 'react';
import {
  chakra, Box, Container, Flex, useColorModeValue, SimpleGrid, HStack, Button,
  Grid, GridItem,
  FormLabel, Input, InputGroup, InputRightAddon, Text, FormControl, FormErrorMessage,
  Textarea, Spinner
} from "@chakra-ui/react";
import { useField } from "formik";
import { OcrButton } from "./ocrInput";
import { atom, useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { ocrLoadingState } from "./ocrInput";

export const isInvalid = (meta: any) => {
  if (meta.touched === false) return undefined;
  if (meta.touched && meta.error === undefined) return false;
  return true;
}

export const InputComponent = ({ name, label, type, postfix, isRequired }:
  {
    name: string,
    label: string,
    type?: string,
    postfix?: string,
    isRequired?: boolean
  }) => {

  const [field, meta, helpers] = useField({name, type: type?? 'string'})


  const _input = () =>
    <Input {...field}
      focusBorderColor="blue.200"
      rounded="md"
    />;

  return (
    <>
      <FormControl 
        as={GridItem} 
        colSpan={6} 
        pt={name == 'targetHospitalDepartment' ? undefined : 4}
        pb={4}
        isRequired={isRequired ?? false}
        isInvalid={isInvalid(meta)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
      >
        <FormLabel htmlFor={name} fontSize='md'>{label}</FormLabel>
        {postfix ?
          <InputGroup>
            {_input()}
            <InputRightAddon bg="gray.50" color={"gray.500"} rounded="md">
              <Text fontSize="sm">{postfix}</Text>
            </InputRightAddon>
          </InputGroup>
          : _input()
        }
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </>
  );
}


export const TextAreaComponent = ({ name, label, rows, isRequired, ocrType }: {
  name: string,
  label: string,
  rows?: number,
  isRequired?: boolean,
  ocrType?: string
}) => {

  const [field, meta, helpers] = useField(name)
  const [loading, setLoading] = useRecoilState(ocrLoadingState);

  let isLoading = false
  switch (ocrType) {
    case "medicationNotebook":
      isLoading = loading.medicationNotebook
      break;
    case "chartPlainText":
      isLoading = loading.chartPlainText
      break;
  }

  return (
    <>
      <FormControl as={GridItem} colSpan={6} py={4}
        isRequired={isRequired ?? false}
        isInvalid={isInvalid(meta)}
      >
        <HStack>
          <FormLabel fontSize="md" htmlFor={name} width="11rem">
            {label}
          </FormLabel>
          {ocrType
            ? <OcrButton ocrType={ocrType} />
            : undefined
          }
        </HStack>
        <Box position="relative">
          <Textarea {...field} focusBorderColor="blue.200" rows={rows ?? 2} />
          {ocrType && isLoading
            ? <Flex 
                position="absolute" 
                top="0" 
                right="0" 
                bottom="0" 
                left="0" 
                alignItems="center" 
                justifyContent="center" 
                bgColor="rgba(255, 255, 255, 0.5)"
              >
                <Spinner />
              </Flex>
            : undefined
          }  
        </Box>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </>
  );
}
