import React, { useEffect } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { InputComponent } from '../uiParts/inputFields/common';
import { BirthDateField } from '../uiParts/inputFields/birthDateField';
import { SexSelection } from '../uiParts/inputFields/sexSelection';
import { useFormikContext } from 'formik'
import { useSetRecoilState } from 'recoil';
import { tabErrorState } from '../../states/tabErrorState';
import { get as _get, has as _has} from 'lodash'
import { ImageInput } from '../uiParts/inputFields/imageInput';

export const ImageSection = () => {
  return (
    <SimpleGrid columns={6} spacing={2} rounded="md">
      <ImageInput name="image" label="画像" isRequired={false} />
    </SimpleGrid>
  )
}
