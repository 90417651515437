import { Auth, Storage } from 'aws-amplify';
import axios from "axios";
import { useRecoilSnapshot, useRecoilValue } from 'recoil';
import urlJoin from 'url-join';
import {v4 as uuidv4} from 'uuid';
import { imageProp } from './components/uiParts/inputFields/imageInput';

const API_ROOT = process.env.REACT_APP_API_ROOT;

export const getIdToken = async (): Promise<string> => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch (error) {
    console.error('Failed to get ID Token.');
    return '';
  }
};

type getHospitalInfoResponse = {
  userId: string,
  originHospitalName: string,
  targetHospitals: {
    targetHospitalNserId: string,
    targetHospitalName:  string
  }
}

export const getHospitalInfo = async (username: any): Promise<getHospitalInfoResponse> => {
  const idToken = await getIdToken();
  if (API_ROOT === undefined) throw new Error('API ROOT not set')

  const response = await axios.get<getHospitalInfoResponse>(
    urlJoin(API_ROOT, 'info', username),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  return response.data;
}

export const getReferralHistory = async (userId: any) => {
  const idToken = await getIdToken();
  if (API_ROOT === undefined) throw new Error('API ROOT not set')

  const response = await axios.get(
    urlJoin(API_ROOT, 'history', userId),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  return response.data;
}

export const postReferralData = async (userId: string, referralData: any, imageData: imageProp[]) => {
  const idToken = await getIdToken();
  if (API_ROOT === undefined) throw new Error('API ROOT not set')

  const data = {
    userId: userId,
    targetHospitalNserId: referralData.targetHospitalNserId,
    referralData: referralData,
  }

  // send Image
  // imageData.map((image) => uploadImageS3({file:image.file, id:image.fileId}))

  const response = await axios.post(
    urlJoin(API_ROOT, userId, 'referralData'),
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    }
  );

  imageData.forEach((image)=>{
    const fileType = image.file.type.split('/')[1];
    const imageFile =  `${uuidv4()}.${fileType}`;

    uploadImageS3({file:image.file, id:`${response.data}/${imageFile}`})
  })
 
  return response.data;
};


export const uploadImageS3 = async ({file, id}:{file: File, id:string}) => {
  const result = await Storage.put(id, file);
  return result.key;
}


export const getOcrResult = async (base64Img: string, hospitalName: string, ocrType?: string) => {
  const idToken = await getIdToken();
  if (API_ROOT === undefined) throw new Error('API ROOT not set')

  interface requestData {
    image: string,
    localize?: boolean,
    encounterID?: string,
  }

  const data: requestData = {
    image: base64Img,
    encounterID: hospitalName,
  }

  if (ocrType === "chartPlainText") {
    // 画像の返却有無 false=>返却無し
    data.localize = false
  }

  const response = await axios.post(
    urlJoin(API_ROOT, "/ocr/" + ocrType),
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  return response.data;
};
