import { selector } from 'recoil';
import { getHospitalInfo } from '../apiService';
import { cognitoUserState } from './cognitoUser';

type targetHospital = {
  targetHospitalName: string,
  targetHospitalNserId: string
}

type originHospitalInfo = {
  userId: string,
  originHospitalName: string,
  targetHospitals: targetHospital[]
}

export const originHospitalInfoState = selector<originHospitalInfo | any>({
  key: 'OriginHospitalInfo',
  get: async ({ get }) => {
    const val = await getHospitalInfo(get(cognitoUserState))
    return val;
  },
});
