import React, { useState } from 'react';
import { Box, Container, useColorModeValue, Stack, Button, Tabs, TabList, TabPanels, TabPanel, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { TabBlock } from '../uiBlocks/tabs';
import { NavigationBar } from '../uiBlocks/navigationBar';
import { HospitalInfoSection } from '../uiBlocks/hospitalInfoSection';
import { PatientInfoSection } from '../uiBlocks/patientInfoSection';
import { ReferralContentSection } from '../uiBlocks/referralContentSection';
import { ReferralContentPrintSection } from '../uiBlocks/referralContentPrintSection';
import { AfterPostModal, afterPostModalStatus } from '../uiBlocks/afterPostModal';
import { initialValues, validationSchema } from '../../validation';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { originHospitalInfoState } from '../../states/originHospitalInfo';
import { postReferralData } from '../../apiService';
import { birthDateState } from '../uiParts/inputFields/birthDateField';
import { tabErrorCounter } from '../../states/tabErrorState';
import { FiLogOut, FiFilePlus, FiTrash } from "react-icons/fi"
import { ImageSection } from '../uiBlocks/imageSection';
import { imageInputeState } from '../uiParts/inputFields/imageInput';


export const InputPage = () => {
  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      h="100%"
      minH="100vh"
    >
      <Container
        maxW={["100%", "100%", "90%", "80%", "70%"]}
        centerContent
        py={4}
      >
        <Stack
          w="100%"
          spacing={6}
          rounded="md"
        >

          {/** top page menu */}
          <NavigationBar />

          {/** 紹介状 */}
          <React.Suspense fallback="">
            <_Formik />
          </React.Suspense>
        </Stack>
      </Container>
    </Box>);
};

const _Formik = () => {
  const originHospitalInfo = useRecoilValue(originHospitalInfoState);
  const setModalState = useSetRecoilState(afterPostModalStatus)
  const resetBirthDateStatus = useResetRecoilState(birthDateState)

  const hospitalInfoErrorCount = useRecoilValue(tabErrorCounter('hospitalInfo'))
  const patientInfoErrorCount = useRecoilValue(tabErrorCounter('patientInfo'))
  const referralContentErrorCount = useRecoilValue(tabErrorCounter('referralContent'))

  const imageInputState  = useRecoilValue(imageInputeState('image'))
  const resetImageInputState = useResetRecoilState(imageInputeState('image'))

  // 現在選択されているタブを管理
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleTabChange = (index:any) => {
    setSelectedIndex(index)
  }

  return (
    <Formik
      initialValues={{
        ...initialValues,
        originHospitalName: originHospitalInfo.originHospitalName
      }}
      validationSchema={validationSchema}
      onReset={() => {
        // reset birthdate input status
        resetBirthDateStatus()
        // reset Image selection
        resetImageInputState()
      }}
      onSubmit={async (values, actions) => {
        // open modal
        setModalState(true)
        // post data
        try {
          await postReferralData(originHospitalInfo.userId, values, imageInputState)
        }
        catch (err) {
          // TODO
          setModalState(false)
        }
      }}
    >{(props) => (
      <>
        <Form noValidate>
          <Box bg={"white"} p={{ sm: 6 }} >
            <Tabs
              size="lg"
              orientation='horizontal'
              variant={'enclosed-colored'}
              onChange={(index) => handleTabChange(index)}
              >
              <TabList>
                <TabBlock tabLabel={"医療機関"} errorCount={hospitalInfoErrorCount} />
                <TabBlock tabLabel={"患者情報"} errorCount={patientInfoErrorCount} />
                <TabBlock tabLabel={"紹介内容"} errorCount={referralContentErrorCount} />
                <TabBlock tabLabel={"画像"}  />
                <TabBlock tabLabel={"紹介状作成"} />
              </TabList>

              <TabPanels >
                <TabPanel> <HospitalInfoSection /> </TabPanel>
                <TabPanel> <PatientInfoSection /> </TabPanel>
                <TabPanel> <ReferralContentSection /> </TabPanel>
                <TabPanel> <ImageSection/> </TabPanel>
                <TabPanel> <ReferralContentPrintSection/> </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>

          {/** props.errors ?
          <div>{Object.values(props.errors).map((error) =><div key={error}>{error}</div>)}</div>
    : <></>*/}

        {/* 紹介状作成タブでは送信ボタンを隠す */}
        {(selectedIndex != 4) 
          ? <HStack bg={"white"} p={{ sm: 6 }} >
            <Button
              type="submit"
              colorScheme="teal"
              _focus={{ shadow: "" }}
              // isDisabled={props.errors!=={}}
              fontWeight="md"
              w="100%" >
              送信
            </Button>
            <Button
              rightIcon={<FiTrash size={16} />}
              variant="outline"
              colorScheme="orange"
              fontWeight="md"
              onClick={() => props.resetForm()}
            >
              リセット
            </Button>
          </HStack>
          : ''}
        </Form>
        <AfterPostModal />
      </>
    )}
    </Formik >
  )
}


