import {
  Checkbox,
  FormControl,
  GridItem
} from "@chakra-ui/react";
import { useField } from "formik";

export const EmergencyCheck = ({ name }: { name: string }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl
      as={GridItem}
      colSpan={6}
      pt={4}
      isRequired={false}
    >
      <Checkbox  {...field} isChecked={field.value} onChange={(e)=>{ helpers.setValue(e.target.checked); }}>
        救急外来向けに紹介
      </Checkbox>
    </FormControl>
  );
};
