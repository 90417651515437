import React, { useEffect } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import {
  InputComponent,
  TextAreaComponent,
} from "../uiParts/inputFields/common";
import { useFormikContext } from "formik";
import { useSetRecoilState } from "recoil";
import { tabErrorState } from "../../states/tabErrorState";
import { get as _get, has as _has } from "lodash";

export const ReferralContentSection = () => {
  const { errors, touched } = useFormikContext();
  const setPatientInfoErrorState = useSetRecoilState(
    tabErrorState("referralContent")
  );
  const fieldNames = [
    "diagnosis",
    "referralIntention",
    "pastMedicalHistory",
    "familySocialHistory",
    "referralSummary",
    "medication",
    "comment",
  ];

  useEffect(() => {
    let errorFields: string[] = [];
    fieldNames.map((fn) => {
      if (_has(errors, fn) && _get(errors, fn) && _get(touched, fn)) {
        errorFields.push(fn);
      }
    });
    setPatientInfoErrorState(errorFields);
  }, [errors, touched]);

  return (
    <SimpleGrid columns={6} spacing={2} rounded="md">
      <InputComponent name="diagnosis" label="診断名" />

      <InputComponent
        name="referralIntention"
        label="紹介目的"
        isRequired={false}
      />

      <TextAreaComponent name="pastMedicalHistory" label="既往歴" />

      <TextAreaComponent name="familySocialHistory" label="家族歴・その他" />

      <TextAreaComponent
        name="referralSummary"
        label="紹介に至った経緯"
        rows={10}
        isRequired={false}
        ocrType="chartPlainText"
      />
      <TextAreaComponent name="medication" label="現在の処方・内服" rows={5} ocrType="medicationNotebook" />

      <TextAreaComponent name="comment" label="コメント" />
    </SimpleGrid>
  );
};
