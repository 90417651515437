import React, { useEffect } from 'react';
import { InputPage } from './components/pages/inputForms';
import { useSetRecoilState } from 'recoil';
import { cognitoUserState } from './states/cognitoUser'
import { Box } from '@chakra-ui/react';

import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';


export const App = () => {
  const { route, user } = useAuthenticator(context => [context.route]);
  const setCognitoUser = useSetRecoilState(cognitoUserState);
  useEffect(() => {
    if (user!== undefined && user.username !== undefined) {
      setCognitoUser(user.username)
    }
  }, [user, setCognitoUser]);

  return route === 'authenticated' ? <InputPage />
    : <Box h={'100vh'} display='flex' justifyContent='center'>
      <Authenticator hideSignUp={true} />
    </Box>
}
