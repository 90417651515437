import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { targetHospitalState } from "../../../states/targetHospitals";
import { birthDateState, birthDateStateSetter } from "./birthDateField";

export const HospitalSelection = ({ name }: { name: string }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Box gridArea={name}>
      <FormControl as={GridItem} colSpan={5}>
        <Flex direction="row" align="center">
          <FormLabel fontSize={"md"} htmlFor={name}>
            紹介先医療機関
          </FormLabel>
          <Select {...field} placeholder="選択してください" flex="1">
            <React.Suspense fallback="">
              <HospitalOption />
            </React.Suspense>
          </Select>
        </Flex>
      </FormControl>
    </Box>
  );
};

const HospitalOption = () => {
  const targetHospitals = useRecoilValue(targetHospitalState);

  return (
    <>
      {targetHospitals.map((h) => {
        return (
          <option value={h.targetHospitalNserId} key={h.targetHospitalNserId}>
            {h.targetHospitalName}
          </option>
        );
      })}
    </>
  );
};

export const InputComponent = ({
  name,
  label,
  type,
  suffix,
  align,
  width,
  colSpan,
  columnStart,
}: {
  name: string;
  label?: string;
  type?: string;
  suffix?: string;
  align?: string;
  width?: string;
  colSpan?: string;
  columnStart?: string;
}) => {
  const [field, meta, helpers] = useField({ name, type: type ?? "string" });

  // ラベルの縦位置/横位置の設定
  const textAlign: any = align ? align : "right";
  const formWidth = width ? width : "28";

  return (
    <Box gridArea={name}>
      <FormControl
        as={GridItem}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Flex direction="row" align="center">
          {label ? (
            <FormLabel
              htmlFor={name}
              fontSize="md"
              textAlign={textAlign}
              width={formWidth}
            >
              {label}
            </FormLabel>
          ) : undefined}
          <Input {...field} focusBorderColor="blue.200" rounded="md" flex="1" />
          {suffix ? (
            <FormLabel fontSize="md" textAlign="center" margin={2}>
              {suffix}
            </FormLabel>
          ) : undefined}
        </Flex>
      </FormControl>
    </Box>
  );
};

export const TextAreaComponent = ({
  name,
  label,
  rows,
  align,
  width,
}: {
  name: string;
  label: string;
  rows?: number;
  align?: string;
  width?: string;
}) => {
  const [field, meta, helpers] = useField(name);
  const lines = label.split("@");
  const textAlign: any = align ? align : "right";
  const formWidth = width ? width : "28";

  return (
    <Box gridArea={name}>
      <FormControl as={GridItem}>
        <Flex direction="row" align="center">
          <FormLabel
            fontSize="md"
            htmlFor={name}
            flexShrink={0}
            width={formWidth}
            textAlign={textAlign}
          >
            {/* {label} */}
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </FormLabel>
          <Textarea
            {...field}
            focusBorderColor="blue.200"
            flex="1"
            rows={rows ?? 2}
          />
        </Flex>
      </FormControl>
    </Box>
  );
};

export const PrintInputComponent = ({
  name,
  label,
  type,
  suffix,
  border,
  areaMargin,
  labelPadding,
}: {
  name: string;
  label?: string;
  type?: string;
  suffix?: string;
  border?: string;
  areaMargin?: string;
  labelPadding?: string;
}) => {
  const [field, meta, helpers] = useField({ name, type: type ?? "string" });

  // ラベルの縦位置/横位置の設定
  const lineBorder = border ?? "1px solid";
  const areaMg = areaMargin ?? "0px";
  const labelPd = labelPadding ?? "0px";
  const [age, setAge] = useRecoilState(ageState);

  return (
    <Box gridArea={name} margin={areaMg}>
      <FormControl
        as={GridItem}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Flex direction="row" align="center">
          {label ? (
            <FormLabel marginY={1} fontSize="xs" width={28} padding={labelPd}>
              {label}
            </FormLabel>
          ) : undefined}
          <Text
            borderColor="blackAlpha.700"
            borderRadius={0}
            border={lineBorder}
            height={"2rem"}
            padding="3px 16px"
            flex="1"
            fontSize="md"
          >
            {field.value}
          </Text>
          {suffix ? (
            <FormLabel fontSize="xs" margin={"0px 15px 0px 0px;"}>
              {suffix}
            </FormLabel>
          ) : undefined}
          {name == "patientBirthDate" ? (
            <Flex alignItems={"center"}>（&nbsp;{age}&nbsp;歳&nbsp;）</Flex>
          ) : undefined}
        </Flex>
      </FormControl>
    </Box>
  );
};

export const PrintTextAreaComponent = ({
  name,
  label,
  border,
  labelPadding,
}: {
  name: string;
  label: string;
  border?: string;
  labelPadding?: string;
}) => {
  const [field, meta, helpers] = useField(name);
  const lines = label.split("@");

  const textareaRef = useRef<HTMLParagraphElement>(null);

  const lineBorder = border ?? "1px solid";
  const labelPd = labelPadding ?? "0px";

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const scrollHeight = textareaRef.current.scrollHeight;

      textareaRef.current.style.height = `${scrollHeight + 50}px`;
    }
  }, [field.value]);

  return (
    <Box gridArea={name}>
      <FormControl as={GridItem}>
        <Flex direction="row" align="flex-start">
          <FormLabel
            marginY={1}
            fontSize="xs"
            flexShrink={0}
            width={28}
            padding={labelPd}
          >
            {/* {label} */}
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </FormLabel>
          <Box
            ref={textareaRef}
            borderColor="blackAlpha.700"
            border={lineBorder}
            borderRadius={0}
            flex="1"
            padding="3px 16px"
            resize={"none"}
            overflow={"hidden"}
            fontSize="md"
            whiteSpace={"break-spaces"}
          >
            {field.value}
          </Box>
        </Flex>
      </FormControl>
    </Box>
  );
};

export const PrintHospitalSelection = ({
  name,
  border,
}: {
  name: string;
  border?: string;
}) => {
  const [field, meta, helpers] = useField(name);
  const targetHospitals = useRecoilValue(targetHospitalState);
  const lineBorder = border ?? "1px solid";
  const hospitalName = targetHospitals.map((item) => {
    if (item.targetHospitalNserId == field.value) {
      return item.targetHospitalName;
    }
  });

  return (
    <Box gridArea={name}>
      <FormControl
        as={GridItem}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Flex direction="row" align="center">
          <FormLabel
            marginY={1}
            fontSize="xs"
            width={28}
            justifyContent={"center"}
          >
            紹介先医療機関
          </FormLabel>
          <Text
            border={lineBorder}
            borderColor="blackAlpha.700"
            borderRadius={0}
            height={"30px"}
            padding="5px 16px"
            flex="1"
            fontSize="md"
          >
            {hospitalName}
          </Text>
        </Flex>
      </FormControl>
    </Box>
  );
};

export const ageState = atom({
  key: "ageState",
  default: "",
});

const zenkakuToHankaku = (value: string) => {
  // 数字以外の文字を除去
  value = value.replace(/[^\d０-９]/g, "");
  // 全角数字を半角数値に置換
  return value.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

export const BirthDateField = ({ name }: { name: string }) => {
  const [field, meta, helper] = useField(name);
  const bdState = useRecoilValue(birthDateState);
  const setBdState = useSetRecoilState(birthDateStateSetter);
  const [age, setAge] = useRecoilState(ageState);
  useEffect(() => {
    // 全ての値がセットされてから、formに埋める
    if (
      bdState.era !== undefined &&
      bdState.era !== "" &&
      bdState.year !== undefined &&
      bdState.month !== undefined &&
      bdState.date !== undefined
    ) {
      helper.setValue(
        `${bdState.era} ${bdState.year}年${bdState.month}月${bdState.date}日`
      );
      // 年齢計算
      let offset = 0;
      if (bdState.era === "明治") {
        offset = 1867;
      } else if (bdState.era === "大正") {
        offset = 1911;
      } else if (bdState.era === "昭和") {
        offset = 1925;
      } else if (bdState.era === "平成") {
        offset = 1988;
      } else if (bdState.era === "令和") {
        offset = 2018;
      }
      const birthdate = new Date(
        offset + bdState.year,
        bdState.month - 1,
        bdState.date
      );
      const calAge = calculateAge(birthdate);
      if (!Number.isNaN(calAge)) {
        setAge(String(calAge));
      }
    } else {
      // 空欄になっているinputが一つでもあれば、formをundefineにする
      helper.setError("未入力項目があります。");
      // TODO: custom validation
      setAge("");
    }
  }, [bdState]);

  return (
    <Box gridArea={name}>
      <FormControl
        isRequired={false}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Input {...field} hidden />
        <SimpleGrid
          spacing={2}
          gridTemplateColumns={{
            base: "4rem 1fr 1fr 1fr 1fr 6rem",
          }}
        >
          <FormLabel fontSize="md" textAlign="left" width="4rem">
            生年月日
          </FormLabel>
          <InputGroup as={GridItem}>
            <Select
              isRequired={false}
              value={bdState.era ?? ""}
              placeholder="選択"
              onChange={(e) =>
                setBdState({ path: "era", value: e.target.value })
              }
            >
              <option value="令和">令和</option>
              <option value="平成">平成</option>
              <option value="昭和">昭和</option>
              <option value="大正">大正</option>
              <option value="明治">明治</option>
            </Select>
          </InputGroup>
          <InputGroup>
            <Input
              id="patientBirthdateYear"
              type="text"
              isRequired={false}
              focusBorderColor="blue.200"
              value={bdState.year ?? ""}
              onChange={(e) => {
                const value = e.target.value.slice(0, 2);
                const hankakuData = zenkakuToHankaku(value);
                setBdState({ path: "year", value: hankakuData });
              }}
              flex="1"
              paddingY={"10px"}
            />
            <InputRightAddon
              bg={useColorModeValue("gray.50", "gray.800")}
              color={useColorModeValue("gray.500", "gray.50")}
              rounded="md"
            >
              <Text fontSize="xs">年</Text>
            </InputRightAddon>
          </InputGroup>
          <InputGroup as={GridItem}>
            <Input
              id="patientBirthdateMonth"
              type="text"
              isRequired={false}
              focusBorderColor="blue.200"
              value={bdState.month ?? ""}
              onChange={(e) => {
                const value = e.target.value.slice(0, 2);
                const hankakuData = zenkakuToHankaku(value);
                setBdState({ path: "month", value: hankakuData });
              }}
              flex="1"
              paddingY={"10px"}
            />
            <InputRightAddon
              bg={useColorModeValue("gray.50", "gray.800")}
              color={useColorModeValue("gray.500", "gray.50")}
              rounded="md"
            >
              <Text fontSize="xs">月</Text>
            </InputRightAddon>
          </InputGroup>
          <InputGroup as={GridItem}>
            <Input
              id="patientBirthdateDate"
              type="text"
              isRequired={false}
              focusBorderColor="blue.200"
              value={bdState.date ?? ""}
              onChange={(e) => {
                const value = e.target.value.slice(0, 2);
                const hankakuData = zenkakuToHankaku(value);
                setBdState({ path: "date", value: hankakuData });
              }}
              flex="1"
              paddingY={"10px"}
            />
            <InputRightAddon
              bg={useColorModeValue("gray.50", "gray.800")}
              color={useColorModeValue("gray.500", "gray.50")}
              rounded="md"
            >
              <Text fontSize="xs">日</Text>
            </InputRightAddon>
          </InputGroup>
          <Flex alignItems={"center"}>（&nbsp;{age}&nbsp;歳&nbsp;）</Flex>
        </SimpleGrid>
      </FormControl>
    </Box>
  );
};

export const SexSelection = ({ name }: { name: string }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Box gridArea={name}>
      <FormControl isRequired={false}>
        <Flex direction="row" align="center">
          <FormLabel htmlFor={name} fontSize="md" width="4rem">
            性別
          </FormLabel>
          <RadioGroup {...field} onChange={helpers.setValue}>
            <HStack direction="row" spacing={2}>
              <Radio name={`${name}_male`} value="1">
                男性
              </Radio>
              <Radio name={`${name}_female`} value="2">
                女性
              </Radio>
            </HStack>
          </RadioGroup>
        </Flex>
      </FormControl>
    </Box>
  );
};

// 年齢を計算する関数
const calculateAge = (birthdate: any) => {
  const today = new Date();
  const thisYearBirthday = new Date(
    today.getFullYear(),
    birthdate.getMonth(),
    birthdate.getDate()
  );
  let age = today.getFullYear() - birthdate.getFullYear();
  if (today < thisYearBirthday) {
    age--;
  }
  return age;
};

export const PrintSexSelection = () => {
  const [field, meta, helpers] = useField("patientSex");
  const [displaySex, setDisplaySex] = useState("　");

  useEffect(() => {
    if (field.value == 1) {
      setDisplaySex("男");
    } else if (field.value == 2) {
      setDisplaySex("女");
    }
  }, [field.value]);

  return (
    <Box gridArea={"patientSex"}>
      <FormControl
        as={GridItem}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Flex direction="row" align="center">
          <FormLabel marginY={1} fontSize="xs" textAlign="right" width={28}>
            性別
          </FormLabel>
          <Text
            border={"none"}
            borderColor="blackAlpha.700"
            borderRadius={0}
            height={"auto"}
            padding="5px"
            flex="1"
            fontSize="md"
            width={"2rem"}
            textAlign="center"
          >
            {displaySex}
          </Text>
        </Flex>
      </FormControl>
    </Box>
  );
};
