import { Flex, Spinner } from "@chakra-ui/react";

export const LoadingSpinner = () => {
  return (
    <Flex
      className="loading-apinner"
      position="absolute"
      top="0"
      right="0"
      bottom="0"
      left="0"
      alignItems="center"
      justifyContent="center"
      bgColor="rgba(255, 255, 255, 0.5)"
    >
      <Spinner />
    </Flex>
  );
};
