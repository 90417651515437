import React from "react";
import {
  GridItem,
  FormLabel,
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Radio,
  HStack,
} from "@chakra-ui/react";
import { useField, Field } from "formik";
import { isInvalid } from "./common";

export const SexSelection = ({ name }: { name: string }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl
      as={GridItem}
      colSpan={6}
      py={4}
      isRequired={false}
      isInvalid={isInvalid(meta)}
    >
      <FormLabel htmlFor={name} fontSize="md">
        性別
      </FormLabel>
      <RadioGroup {...field} onChange={helpers.setValue}>
        <HStack direction="row" spacing={2}>
          <Radio name={`${name}_male`} value="1">
            男性
          </Radio>
          <Radio name={`${name}_female`} value="2">
            女性
          </Radio>
        </HStack>
      </RadioGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
