import React from 'react'
import {
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { useFormikContext } from 'formik'

export const afterPostModalStatus = atom({
  key: 'afterPostModalStatus',
  default: false,
})

export const AfterPostModal = () => {
  const [modalState, setModalState] = useRecoilState(afterPostModalStatus)
  const onClose = () => setModalState(false);
  const { isSubmitting } = useFormikContext();

  return (
    <Modal isOpen={modalState} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {isSubmitting ? <ModalHeader>送信中</ModalHeader> : <ModalHeader>送信完了</ModalHeader>}
        <ModalCloseButton />
        <ModalBody my={4} >
          {isSubmitting ?
            <Center><Spinner /></Center>
            : <p>紹介状データを送信しました。</p>
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
