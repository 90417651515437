import { Box, Button, SimpleGrid, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSetRecoilState } from "recoil";
import { tabErrorState } from "../../states/tabErrorState";
import {
  BirthDateField,
  HospitalSelection,
  InputComponent,
  PrintHospitalSelection,
  PrintInputComponent,
  PrintSexSelection,
  PrintTextAreaComponent,
  SexSelection,
  TextAreaComponent,
} from "../uiParts/inputFields/printView";

export const ReferralContentPrintSection = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    return (
      <div>
        {/* 入力エリア */}
        <ContentArea />
        <div style={{ position: "fixed" }}>
          {/* 印刷エリア */}
          <PrintArea ref={componentRef} />
        </div>
        <Button
          colorScheme="teal"
          _focus={{ shadow: "" }}
          fontWeight="md"
          w="8rem"
          onClick={handlePrint}
        >
          紹介状印刷
        </Button>
      </div>
    );
  }
);

const ContentArea = () => {
  const { errors, touched } = useFormikContext();
  const setPatientInfoErrorState = useSetRecoilState(
    tabErrorState("referralContent")
  );

  const year = "2002";
  const month = "12";
  const day = "30";

  return (
    <SimpleGrid
      spacing={2}
      rounded="md"
      margin={3}
      gridTemplateColumns={{
        base: "1fr 1fr 1fr 1fr 1fr 1fr",
      }}
      gridTemplateRows={{
        // base: "auto"
        base: "auto auto auto 3rem auto auto 2rem auto 2rem auto auto auto auto auto auto auto",
      }}
      gridTemplateAreas={{
        base: `'printTitle printTitle printTitle printTitle printTitle printTitle'
                     'targetHospitalNserId targetHospitalNserId targetHospitalNserId targetHospitalNserId  .  .'
                     'targetHospitalDepartment targetHospitalDepartment targetHospitalDoctor targetHospitalDoctor  .  .'
                     '. . . . nowDate nowDate'
                     ' .  .  originHospitalName originHospitalName originHospitalName originHospitalName'
                     ' .  .  temp temp originHospitalDoctor originHospitalDoctor'
                     '. . . . . .'
                     'patientArea patientArea patientArea patientArea patientArea patientArea'
                     '. . . . . .'
                     'diagnosis diagnosis diagnosis diagnosis diagnosis diagnosis'
                     'referralIntention referralIntention referralIntention referralIntention referralIntention referralIntention'
                     'pastMedicalHistory pastMedicalHistory pastMedicalHistory pastMedicalHistory pastMedicalHistory pastMedicalHistory'
                     'familySocialHistory familySocialHistory familySocialHistory familySocialHistory familySocialHistory familySocialHistory'
                     'referralSummary referralSummary referralSummary referralSummary referralSummary referralSummary'
                     'medication medication medication medication medication medication'
                     'comment comment comment comment comment comment'
                     `,
      }}
    >
      {/* タイトル */}
      <DocumentTitle />
      {/* 紹介先医療機関 */}
      <HospitalSelection name="targetHospitalNserId" />
      {/* 紹介先診療科 */}
      <InputComponent
        name="targetHospitalDepartment"
        label="診療科・医師名"
        suffix="科"
      />
      {/* 紹介先医師名 */}
      <InputComponent name="targetHospitalDoctor" suffix="先生" />
      {/* 年月日 */}
      <CreateDate />

      {/* 紹介元医療機関 */}
      <InputComponent name="originHospitalName" label="紹介元医療機関" />
      {/* 紹介元診療科 */}
      <InputComponent name="temp" label="診療科・医師名" suffix="科" />
      {/* 紹介元医師名 */}
      <InputComponent name="originHospitalDoctor" suffix="印" />

      {/* 患者情報 */}
      <PatientArea />

      {/* 傷病名 */}
      <InputComponent name="diagnosis" label="傷病名" />
      {/* 紹介目的 */}
      <InputComponent name="referralIntention" label="紹介目的" />
      {/* 既往歴 */}
      <TextAreaComponent name="pastMedicalHistory" label="既往歴" />
      {/* 家族歴その他 */}
      <TextAreaComponent name="familySocialHistory" label="家族歴・その他" />
      {/* 症状経過 */}
      {/* 検査結果 */}
      {/* 治療経過 */}
      <TextAreaComponent
        name="referralSummary"
        label="症状経過@検査結果@治療経過"
        rows={10}
      />
      {/* 現在の処方薬 */}
      <TextAreaComponent name="medication" label="現在の処方薬" rows={5} />
      {/* 備考 */}
      <TextAreaComponent name="comment" label="備考" />
    </SimpleGrid>
  );
};

const PrintArea = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { errors, touched } = useFormikContext();
  const setPatientInfoErrorState = useSetRecoilState(
    tabErrorState("referralContent")
  );

  const year = "2002";
  const month = "12";
  const day = "30";

  return (
    <div ref={ref}>
      <div className="page-break" />
      <SimpleGrid
        spacing={2}
        rounded="md"
        paddingX={16}
        paddingY={6}
        width="795px"
        gridTemplateColumns={{
          base: "1fr 1fr 1fr 1fr 1fr 1fr",
        }}
        gridTemplateRows={{
          base: "auto auto auto 3rem auto auto auto auto auto auto auto auto auto auto auto auto",
        }}
        gridTemplateAreas={{
          base: `'printTitle printTitle printTitle printTitle printTitle printTitle'
                     'targetHospitalNserId targetHospitalNserId targetHospitalNserId targetHospitalNserId targetHospitalNserId targetHospitalNserId'
                     'targetHospitalDepartment targetHospitalDepartment targetHospitalDoctor targetHospitalDoctor . .'
                     '. . . . nowDate nowDate'
                     ' .  .  originHospitalName originHospitalName originHospitalName originHospitalName'
                     ' .  .  temp temp originHospitalDoctor originHospitalDoctor'
                     '. . . . . .'
                     'printPatientArea printPatientArea printPatientArea printPatientArea printPatientArea printPatientArea'
                     '. . . . . .'
                     'diagnosis diagnosis diagnosis diagnosis diagnosis diagnosis'
                     'referralIntention referralIntention referralIntention referralIntention referralIntention referralIntention'
                     'pastMedicalHistory pastMedicalHistory pastMedicalHistory pastMedicalHistory pastMedicalHistory pastMedicalHistory'
                     'familySocialHistory familySocialHistory familySocialHistory familySocialHistory familySocialHistory familySocialHistory'
                     'referralSummary referralSummary referralSummary referralSummary referralSummary referralSummary'
                     'medication medication medication medication medication medication'
                     'comment comment comment comment comment comment'
                     `,
        }}
      >
        {/* タイトル */}
        <DocumentTitle />
        {/* 紹介先医療機関 */}
        <PrintHospitalSelection name="targetHospitalNserId" border="none" />
        {/* 紹介先診療科 */}
        <PrintInputComponent
          name="targetHospitalDepartment"
          label="診療科・医師名"
          suffix="科"
          border="none"
        />
        {/* 紹介先医師名 */}
        <PrintInputComponent
          name="targetHospitalDoctor"
          suffix="先生"
          border="none"
        />
        {/* 年月日 */}
        <CreateDate />

        {/* 紹介元医療機関 */}
        <PrintInputComponent
          name="originHospitalName"
          label="紹介元医療機関"
          border="none"
          areaMargin="0px 0px 5px 0px"
        />
        {/* 紹介元診療科 */}
        <PrintInputComponent
          name="temp"
          label="診療科・医師名"
          suffix="科"
          border="none"
        />
        {/* 紹介元医師名 */}
        <PrintInputComponent
          name="originHospitalDoctor"
          suffix="印"
          border="none"
        />

        {/* 患者情報 */}
        <PrintPatientArea />

        {/* 傷病名 */}
        <PrintInputComponent name="diagnosis" label="傷病名" labelPadding="0px 0px 0px 12px"/>
        {/* 紹介目的 */}
        <PrintInputComponent name="referralIntention" label="紹介目的" labelPadding="0px 0px 0px 12px"/>
        {/* 既往歴 */}
        <PrintTextAreaComponent name="pastMedicalHistory" label="既往歴" labelPadding="0px 0px 0px 12px"/>
        {/* 家族歴その他 */}
        <PrintTextAreaComponent
          name="familySocialHistory"
          label="家族歴・その他"
          labelPadding="0px 0px 0px 12px"
        />
        {/* 症状経過 */}
        {/* 検査結果 */}
        {/* 治療経過 */}
        <PrintTextAreaComponent
          name="referralSummary"
          label="症状経過@検査結果@治療経過"
          labelPadding="0px 0px 0px 12px"
        />
        {/* 現在の処方薬 */}
        <PrintTextAreaComponent name="medication" label="現在の処方薬" labelPadding="0px 0px 0px 12px"/>
        {/* 備考 */}
        <PrintTextAreaComponent name="comment" label="備考" labelPadding="0px 0px 0px 12px"/>
      </SimpleGrid>
    </div>
  );
});

// 患者情報エリア
const PatientArea = () => {
  return (
    <Box
      gridArea="patientArea"
      border="1px solid"
      borderColor="blackAlpha.700"
      padding={3}
    >
      <SimpleGrid
        spacing={2}
        rounded="md"
        gridTemplateColumns={{
          base: "4rem 1fr 1fr 1fr 1fr 1fr",
        }}
        gridTemplateRows={{
          base: "auto auto auto auto auto",
        }}
        gridTemplateAreas={{
          base: `'patientName patientName  patientName  .  .  .'
                'patientAddress patientAddress patientAddress patientAddress patientAddress patientAddress'
                'patientPhoneNumber patientPhoneNumber  patientPhoneNumber  .  .  .'
                'patientBirthDate patientBirthDate patientBirthDate patientBirthDate patientBirthDate patientBirthDate'
                'patientSex patientSex patientSex . . .'
                `,
        }}
      >
        {/* 患者氏名 */}
        <InputComponent
          name="patientName"
          label="患者氏名"
          align="left"
          width="4rem"
        />
        {/* 患者住所 */}
        <TextAreaComponent
          name="patientAddress"
          label="患者住所"
          align="left"
          width="4rem"
        />
        {/* 電話番号 */}
        <InputComponent
          name="patientPhoneNumber"
          label="電話番号"
          align="left"
          width="4rem"
        />
        {/* 生年月日 */}
        {/* <InputComponent name="aa" label="生年月日"/> */}
        <BirthDateField name="patientBirthDate" />

        {/* 性別 */}
        <SexSelection name="patientSex" />

        {/* <InputComponent name="bb" label="性別"/> */}
      </SimpleGrid>
    </Box>
  );
};

// 患者情報エリア（印刷用）
const PrintPatientArea = () => {
  return (
    <Box
      gridArea="printPatientArea"
      border="1px solid"
      borderColor="blackAlpha.700"
      padding={3}
    >
      <SimpleGrid
        spacing={2}
        rounded="md"
        gridTemplateColumns={{
          base: "1fr 1fr 1fr 1fr 1fr 1fr",
        }}
        gridTemplateRows={{
          base: "auto auto auto auto",
        }}
        gridTemplateAreas={{
          base: `'patientName patientName patientName patientName patientName patientName'
                'patientAddress patientAddress patientAddress patientAddress patientAddress patientAddress'
                'patientPhoneNumber patientPhoneNumber patientPhoneNumber patientPhoneNumber patientPhoneNumber patientPhoneNumber'
                'patientBirthDate patientBirthDate patientBirthDate patientBirthDate patientSex patientSex'
                `,
        }}
      >
        {/* 患者氏名 */}
        <PrintInputComponent
          name="patientName"
          label="患者氏名"
          border="none"
        />
        {/* 患者住所 */}
        <PrintTextAreaComponent
          name="patientAddress"
          label="患者住所"
          border="none"
        />
        {/* 電話番号 */}
        <PrintInputComponent
          name="patientPhoneNumber"
          label="電話番号"
          border="none"
        />
        {/* 生年月日 */}
        <PrintInputComponent
          name="patientBirthDate"
          label="生年月日"
          border="none"
        />
        {/* 性別 */}
        <PrintSexSelection />
      </SimpleGrid>
    </Box>
  );
};

// 文書タイトル
const DocumentTitle = () => {
  return (
    <Box
      gridArea="printTitle"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Text as="b" fontSize="2xl">
        診療情報提供書
      </Text>
    </Box>
  );
};

// 文書作成日
const CreateDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();

  return (
    <Box
      gridArea="nowDate"
      display="flex"
      alignItems="end"
      justifyContent="end"
    >
      <Text fontSize="md" textAlign="right">
        {year}&nbsp;&nbsp;年&nbsp;&nbsp;{month}&nbsp;&nbsp;月&nbsp;&nbsp;{date}
        &nbsp;&nbsp;日
      </Text>
    </Box>
  );
};
