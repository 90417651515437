import React from 'react';
import { Text, VStack, HStack, Tab, Badge, Tag, Spacer } from "@chakra-ui/react";

export const TabBlock = ({ tabLabel, errorCount}: { tabLabel: string, errorCount?: number}) => {
  return (
    <Tab >
      <HStack>
        <Text>{tabLabel}</Text>
        <Spacer />
        {errorCount ? // errorCount is not null and greater than 0
          <Tag colorScheme='red'>エラー {errorCount}</Tag>
          :<></> }
      </HStack>
    </Tab>
  );
}
