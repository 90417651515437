import { selector } from "recoil";
import { originHospitalInfoState } from "./originHospitalInfo";

type targetHospital = {
  targetHospitalName: string;
  targetHospitalNserId: string;
}

export const targetHospitalState = selector<targetHospital[]>({
  key: "targetHospital",
  get: ({ get }) => get(originHospitalInfoState).targetHospitals,
})
