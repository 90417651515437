import * as Yup from 'yup';

export const initialValues = {
  targetHospitalNserId: '',
  targetHospitalDepartment: '',
  targetHospitalDoctor: '',
  originHospitalName: '', // TODO: set from server
  originHospitalDoctor: '',
  patientName: '',
  patientBirthDate: '',
  patientSex: '',
  patientAddress: '',
  patientPhoneNumber: '',
  diagnosis: '',
  referralIntention: '',
  pastMedicalHistory: '',
  familySocialHistory: '',
  referralSummary: '',
  medication: '',
  comment: '',
  image: '',
  emergencyFlag: false,
}


export const validationSchema = Yup.object({
  targetHospitalNserId: Yup.string()
    .required('紹介先医療機関名は必須です。'),
  targetHospitalDepartment: Yup.string().when('emergencyFlag', {
    is: false,
    then: Yup.string().required('紹介先医療機関診療科は必須です。'),
    otherwise: Yup.string().notRequired(),
  }),
  targetHospitalDoctor: Yup.string(),
  originHospitalName: Yup.string()
    .required('紹介元医療機関名は必須です。'),
  originHospitalDoctor: Yup.string(),
  patientName: Yup.string(),
  //   .required('患者氏名は必須です。'),
  patientBirthDate: Yup.string(),
  //   .required('患者生年月日は必須です。'),
  patientSex: Yup.number()
    .integer().min(1).max(2),
  //  .required('性別は必須です。'),
  patientAddress: Yup.string(),
  patientPhoneNumber: Yup.string(),
  diagnosis: Yup.string(),
  referralIntention: Yup.string(),
  //  .required('紹介目的は必須です。'),
  pastMedicalHistory: Yup.string(),
  familySocialHistory: Yup.string(),
  referralSummary: Yup.string(),
  // .required('紹介に至った経緯は必須です。'),
  medication: Yup.string(),
  comment: Yup.string(),
  image: Yup.array().of(Yup.string()),
  emergencyFlag: Yup.boolean(),
})
