import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiDatabase } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import { getReferralHistory } from "../../../apiService";
import { originHospitalInfoState } from "../../../states/originHospitalInfo";
import { LoadingSpinner } from "../feedback";

export const ReferralHistoryButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [historyList, setHistoryList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const originHospitalInfo = useRecoilValue(originHospitalInfoState);

  useEffect(() => {
    if (isOpen) {
      // リストに表示するデータを取得
      const getData = async () => {
        const userId = originHospitalInfo.userId;
        try {
          setIsLoading(true);
          const result = await getReferralHistory(userId);
          setHistoryList(result);
        } catch (error) {
          console.log("error");
        } finally {
          setIsLoading(false);
        }
      };

      getData();
    }
  }, [isOpen]);

  // thのスタイル
  const thStyle = {
    borderRight: "1px solid",
    borderRightColor: "gray.300",
    borderBottomColor: "gray.300",
    whiteSpace: "nowrap",
    textAlign: "center",
  } as const;

  // tdのスタイル
  const tdStyle = {
    borderRight: "1px solid",
    borderRightColor: "gray.300",
    borderBottomColor: "gray.300",
  } as const;

  return (
    <>
      <Button
        rightIcon={<FiDatabase size={16} />}
        variant="outline"
        colorScheme={useColorModeValue("blackAlpha", "whiteAlpha")}
        fontSize="xs"
        fontWeight="bold"
        size="sm"
        marginX="10px"
        onClick={onOpen}
      >
        紹介履歴
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxHeight="80%" minHeight="30%" maxWidth="90%">
          <ModalHeader>紹介履歴</ModalHeader>
          <ModalBody>
            <Box
              maxHeight="700px"
              maxWidth="100%"
              overflowX="auto"
            >
                {isLoading ? <LoadingSpinner /> : ""}  
              <Table variant="simple">
                <Thead position="sticky" top="0" bgColor="white">
                  <Tr>
                    <Th {...thStyle} width="105px">送信日時</Th>
                    <Th {...thStyle} width="105px">年齢</Th>
                    <Th {...thStyle} width="70px">性別</Th>
                    <Th {...thStyle}>診断名</Th>
                    <Th {...thStyle}>紹介目的</Th>
                    <Th {...thStyle} width="170px">送信先病院</Th>
                    <Th borderBottomColor="gray.300" whiteSpace="nowrap" textAlign="center" width="150px">医師名</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {historyList.map((data, index) => (
                    <Tr key={index}>
                      <Td {...tdStyle} textAlign="right">{data.datetime}</Td>
                      <Td {...tdStyle} textAlign="right">{data.age ? `${data.age}歳` : ""}</Td>
                      <Td {...tdStyle} textAlign="center">{data.sex}</Td>
                      <Td {...tdStyle}>{data.diagnosis}</Td>
                      <Td {...tdStyle}>{data.referral_intention}</Td>
                      <Td {...tdStyle}>{data.target_hospital_name}</Td>
                      <Td borderBottomColor="gray.300">{data.target_hospital_doctor}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" marginRight={3} onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};