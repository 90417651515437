import {atomFamily, selectorFamily} from 'recoil';

export const tabErrorState= atomFamily<string[], string>({
  key: 'tabErrorState',
  default: undefined,
})

export const tabErrorCounter =  selectorFamily<number, string>({
  key: 'tabErrorCounter',
  get: (tabName) => ({get}) => {
    const errorState = get(tabErrorState(tabName));
    return errorState ? errorState.length : 0;
  }
})
