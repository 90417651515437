import {
  Box,
  Button,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import Resizer from "react-image-file-resizer";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { getOcrResult } from "../../../apiService";
import { originHospitalInfoState } from "../../../states/originHospitalInfo";
import { birthDateStateSetter } from "./birthDateField";

export const ocrLoadingState = atom({
  key: "ocrLoadingState",
  default: {
    medicationNotebook: false,
    chartPlainText: false,
    license: false,
  },
});

export const OcrButton = ({ ocrType }: { ocrType: string }) => {
  const formik: any = useFormikContext();
  const originHospitalInfo = useRecoilValue(originHospitalInfoState);
  const setBdState = useSetRecoilState(birthDateStateSetter);
  const [loading, setLoading] = useRecoilState(ocrLoadingState);

  const ocrStart = async (event: any) => {
    if (event.target.files?.[0]) {
      // APIパラメーターのリクエスト上限を超える可能性があるので、画像を最大2000*2000にリサイズ
      const resizedImageBase64 = await resizeFile(event.target.files?.[0]);
      // 先頭の「data:image/xxx;base64」を除去
      const ocrStr = String(resizedImageBase64).split(",")[1];

      const prefixMessage = "\n■■■画像から取込■■■\n";
      const errorMessage = "\n■■■画像の取込に失敗しました■■■";

      switch (ocrType) {
        case "medicationNotebook": // お薬手帳
          try {
            // テキストエリアをロック
            setLoading({ ...loading, medicationNotebook: true });
            // API実行
            const result = await getOcrResult(
              ocrStr,
              originHospitalInfo.originHospitalName,
              ocrType
            );
            // 結果が空だった場合、OCRに失敗した旨を表示する
            if (result.OcrTextResults.MedicationNames === null) {
              formik.setFieldValue(
                "medication",
                formik.values["medication"] + errorMessage
              );
              return;
            }
            // お薬手帳の結果は配列で返ってくるので、一文に結合する
            const MedicationNameList =
              result.OcrTextResults.MedicationNames.join("\n");
            // OCRの結果をテキストエリアに反映
            formik.setFieldValue(
              "medication",
              formik.values["medication"] + prefixMessage + MedicationNameList
            );
          } catch (error) {
            formik.setFieldValue(
              "medication",
              formik.values["medication"] + errorMessage
            );
          } finally {
            // 選択した画像をクリア
            event.target.value = "";
            // テキストエリアをアンロック
            setLoading({ ...loading, medicationNotebook: false });
          }
          break;
        case "chartPlainText": // カルテ平文
          try {
            // テキストエリアをロック
            setLoading({ ...loading, chartPlainText: true });
            // API実行
            const result = await getOcrResult(
              ocrStr,
              originHospitalInfo.originHospitalName,
              ocrType
            );
            // 結果が空だった場合、OCRに失敗した旨を表示する
            if (result.OcrTextResults.PlainText1 === null) {
              formik.setFieldValue(
                "referralSummary",
                formik.values["referralSummary"] + errorMessage
              );
              return;
            }
            // OCRの結果をテキストエリアに反映
            formik.setFieldValue(
              "referralSummary",
              formik.values["referralSummary"] +
                prefixMessage +
                result.OcrTextResults.PlainText1
            );
          } catch (error) {
            formik.setFieldValue(
              "referralSummary",
              formik.values["referralSummary"] + errorMessage
            );
          } finally {
            // 選択した画像をクリア
            event.target.value = "";
            // テキストエリアをアンロック
            setLoading({ ...loading, chartPlainText: false });
          }
          break;
        case "license": // 免許証／保険証
          try {
            // テキストエリアをロック
            setLoading({ ...loading, license: true });
            const result = await getOcrResult(
              ocrStr,
              originHospitalInfo.originHospitalName,
              ocrType
            );
            // 氏名
            if (result.OcrTextResults.Name.FullName !== "") {
              formik.setFieldValue(
                "patientName",
                result.OcrTextResults.Name.FullName
              );
            }
            // 性別
            if (result.OcrTextResults.Gender !== "") {
              const sex = result.OcrTextResults.Gender === "M" ? "1" : "2";
              formik.setFieldValue("patientSex", sex);
            }
            // 生年月日
            if (result.OcrTextResults.BirthDay !== "") {
              const year = result.OcrTextResults.BirthDayWareki.Year;
              const month = result.OcrTextResults.BirthDay.Month;
              const day = result.OcrTextResults.BirthDay.Day;
              const era = result.OcrTextResults.BirthDayWareki.Era;
              setBdState({ path: "era", value: era });
              setBdState({ path: "year", value: String(year) });
              setBdState({ path: "month", value: String(month) });
              setBdState({ path: "date", value: String(day) });
            }
            // 住所
            if (result.OcrTextResults.Address !== "") {
              formik.setFieldValue(
                "patientAddress",
                result.OcrTextResults.Address
              );
            }
          } finally {
            // 選択した画像をクリア
            event.target.value = "";
            // テキストエリアをアンロック
            setLoading({ ...loading, license: false });
          }
          break;
      }
    }
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2000,
        2000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  // ボタンの高さを設定
  let height = "1.75rem";
  // ボタンのタイトルを設定
  let buttonTitle = "";
  switch (ocrType) {
    case "medicationNotebook":
      buttonTitle = "お薬手帳OCR";
      break;
    case "chartPlainText":
      buttonTitle = "カルテOCR";
      break;
    case "license":
      buttonTitle = "患者情報OCR";
      height = "2rem";
      break;
  }

  return (
    <FormControl display="inline" as={GridItem} colSpan={6}>
      <FormLabel htmlFor={ocrType} display="inline-block" height={0}>
        <Button as="a" type="submit" height={height} mr={3} mb={3}>
          {buttonTitle}
        </Button>
        <Input
          id={ocrType}
          hidden
          type="file"
          accept="image/*"
          onChange={ocrStart}
        />
      </FormLabel>
      {ocrType == "license" && loading.license == true ? (
        <Spinner />
      ) : undefined}
      {ocrType == "license" ? (
        <Box fontSize="sm" mt={2}>
          ＊免許証・マイナンバーカード・保険証から個人情報を読み込めます
        </Box>
      ) : undefined}
    </FormControl>
  );
};
