import React from 'react';
import {
  GridItem,
  FormLabel, FormControl, FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import { useField } from "formik";
import { isInvalid } from "./common";
import { useRecoilValue } from 'recoil';
import { targetHospitalState } from '../../../states/targetHospitals';

export const HospitalSelection = ({ name }: { name: string }) => {
  const [field, meta, helpers] = useField(name)

  return (
    <FormControl as={GridItem} colSpan={6} py={4} isRequired={true} isInvalid={isInvalid(meta)} >
      <FormLabel fontSize={"md"} htmlFor={name}>紹介先医療機関</FormLabel>
      <Select {...field} placeholder="選択してください">
        <React.Suspense fallback="">
          <HospitalOption />
        </React.Suspense>
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}

const HospitalOption = () => {
  const targetHospitals = useRecoilValue(targetHospitalState);

  return (
    <>
      {targetHospitals.map(h => {
        return (
          <option value={h.targetHospitalNserId} key={h.targetHospitalNserId} >
            {h.targetHospitalName}
          </option>
        );
      })}
    </>
  );
}
