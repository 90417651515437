import React, { Suspense } from "react";
import { Flex, Box, Heading, Spacer, Button,Skeleton, useColorModeValue, } from "@chakra-ui/react";
import { FiLogOut, FiFilePlus } from "react-icons/fi"
import { useRecoilValue } from 'recoil';
import { originHospitalInfoState } from '../../states/originHospitalInfo';
import { ReferralHistoryButton } from '../uiParts/inputFields/referralHistoryButton';

import { useAuthenticator } from '@aws-amplify/ui-react';

export const NavigationBar = () => {
  const {signOut} = useAuthenticator(context => [context.user]);

  return (
    <Flex px="2" align={"center"} gap={2}>
      <Box >
        <Heading
          size='md'
          color={useColorModeValue("gray.400", "gray.200")} >
          NSC&ensp;紹介システム
        </Heading>
        <Suspense fallback={<Skeleton height='1rem'/>}>
          <HospitalNameBlock />
        </Suspense>
      </Box>
      <Spacer />
      <Box >
        <Suspense fallback={<></>}>
          <ReferralHistoryButton/>
        </Suspense>
        <Button
          rightIcon={<FiLogOut size={16} />}
          variant="outline"
          colorScheme={useColorModeValue("blackAlpha", "whiteAlpha")}
          fontSize="xs"
          fontWeight="bold"
          size="sm"
          onClick={signOut}
        >
          ログアウト
        </Button>
      </Box>
    </Flex>
  );
}


const HospitalNameBlock = () => {
  const originHospitalInfo = useRecoilValue(originHospitalInfoState);
  return <Heading size='sm'>{originHospitalInfo.originHospitalName}</Heading>
}
