import React, {useEffect} from 'react';
import { SimpleGrid } from "@chakra-ui/react";
import { HospitalSelection } from '../uiParts/inputFields/hospitalSelection';
import { InputComponent } from '../uiParts/inputFields/common';
import { useFormikContext } from 'formik';
import { tabErrorState } from '../../states/tabErrorState';
import {get as _get, has as _has} from 'lodash';
import { useSetRecoilState } from 'recoil';
import { EmergencyCheck } from "../uiParts/inputFields/emergencyCheck";


export const HospitalInfoSection = () => {
  const { errors, touched, values }: { errors: any, touched: any, values: any } = useFormikContext();

  const setPatientInfoErrorState = useSetRecoilState(tabErrorState('hospitalInfo'))
  const fieldNames = [
    'targetHospitalNserId',
    'targetHospitalDepartment',
    'targetHospitalDoctor',
    'originHospitalName',
    'originHospitalDoctor',
    'emergencyFlag',
  ]

  useEffect(() => {
    let errorFields:string[] = [];
    fieldNames.map((fn) => {if(_has(errors, fn) && _get(errors, fn) && _get(touched, fn)){errorFields.push(fn)}} )
    setPatientInfoErrorState(errorFields)
  }, [errors, touched])

  return (
    <SimpleGrid columns={6} spacing={2} rounded="md">
      <HospitalSelection name="targetHospitalNserId" />

      <EmergencyCheck name="emergencyFlag"/>

      <InputComponent
        name="targetHospitalDepartment"
        label="紹介先医療機関 診療科"
        isRequired={!values.emergencyFlag}
      />

      <InputComponent
        name="targetHospitalDoctor"
        label="紹介先医療機関 医師名"
        postfix='先生 御侍史'
      />

      <InputComponent name="originHospitalName" label="紹介元医療機関" isRequired={true} />

      <InputComponent name="originHospitalDoctor" label="紹介元医療機関 医師名" postfix='先生' />

    </SimpleGrid >
  );
}
