import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useField } from "formik";
import produce from "immer";
import React, { useEffect } from "react";
import {
  atom,
  DefaultValue,
  selector,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { isInvalid } from "./common";

type birthDateStateProps = {
  era: string | undefined;
  year: number | undefined;
  month: number | undefined;
  date: number | undefined;
};

export const birthDateState = atom<birthDateStateProps>({
  key: "birthdateState",
  default: {
    era: undefined,
    year: undefined,
    month: undefined,
    date: undefined,
  },
});

type setterProps = {
  path: "era" | "year" | "month" | "date";
  value: string | number;
};

export const birthDateStateSetter = selector<setterProps | undefined>({
  key: "birthDateStateSetter",
  get: () => undefined,
  set: ({ set, get }, newValue) => {
    let currentValue = get(birthDateState);
    if (
      newValue !== undefined &&
      !(currentValue instanceof DefaultValue) &&
      !(newValue instanceof DefaultValue)
    ) {
      const nextValue = produce(currentValue, (draft) => {
        newValue.path === "era"
          ? (draft.era = newValue.value.toString())
          : (draft[newValue.path] =
              Number(newValue.value) > 0 ? Number(newValue.value) : undefined);
      });
      set(birthDateState, nextValue);
    }
  },
});

const zenkakuToHankaku = (value: string) => {
  // 数字以外の文字を除去
  value = value.replace(/[^\d０-９]/g, "");
  // 全角数字を半角数値に置換
  return value.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

export const BirthDateField = ({ name }: { name: string }) => {
  const [field, meta, helper] = useField(name);
  const bdState = useRecoilValue(birthDateState);
  const setBdState = useSetRecoilState(birthDateStateSetter);

  useEffect(() => {
    // 全ての値がセットされてから、formに埋める
    if (
      bdState.era !== undefined &&
      bdState.era !== "" &&
      bdState.year !== undefined &&
      bdState.month !== undefined &&
      bdState.date !== undefined
    ) {
      helper.setValue(
        `${bdState.era} ${bdState.year}年${bdState.month}月${bdState.date}日`
      );
    } else {
      // 空欄になっているinputが一つでもあれば、formをundefineにする
      helper.setError("未入力項目があります。");
      // TODO: custom validation
    }
  }, [bdState]);
  return (
    <GridItem colSpan={6} py={4}>
      <FormControl
        isRequired={false}
        isInvalid={isInvalid(meta)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Input {...field} hidden />
        <FormLabel fontSize="md">生年月日</FormLabel>
        <SimpleGrid columns={{ base: 3, sm: 12 }} spacing={2}>
          <InputGroup as={GridItem} colSpan={3}>
            <Select
              isRequired={false}
              value={bdState.era ?? ""}
              placeholder="選択"
              onChange={(e) =>
                setBdState({ path: "era", value: e.target.value })
              }
            >
              <option value="令和">令和</option>
              <option value="平成">平成</option>
              <option value="昭和">昭和</option>
              <option value="大正">大正</option>
              <option value="明治">明治</option>
            </Select>
          </InputGroup>
          <InputGroup as={GridItem} colSpan={3}>
            <Input
              id="patientBirthdateYear"
              type="text"
              isRequired={false}
              focusBorderColor="blue.200"
              value={bdState.year ?? ""}
              paddingX={{ base: 3, md: 4 }}
              onChange={(e) => {
                const value = e.target.value.slice(0, 2);
                const hankakuData = zenkakuToHankaku(value);
                setBdState({ path: "year", value: hankakuData });
              }}
            />
            <InputRightAddon
              bg={useColorModeValue("gray.50", "gray.800")}
              color={useColorModeValue("gray.500", "gray.50")}
              rounded="md"
            >
              <Text fontSize="xs">年</Text>
            </InputRightAddon>
          </InputGroup>
          <InputGroup as={GridItem} colSpan={3}>
            <Input
              id="patientBirthdateMonth"
              type="text"
              isRequired={false}
              focusBorderColor="blue.200"
              value={bdState.month ?? ""}
              paddingX={{ base: 3, md: 4 }}
              onChange={(e) => {
                const value = e.target.value.slice(0, 2);
                const hankakuData = zenkakuToHankaku(value);
                setBdState({ path: "month", value: hankakuData });
              }}
            />
            <InputRightAddon
              bg={useColorModeValue("gray.50", "gray.800")}
              color={useColorModeValue("gray.500", "gray.50")}
              rounded="md"
            >
              <Text fontSize="xs">月</Text>
            </InputRightAddon>
          </InputGroup>
          <InputGroup as={GridItem} colSpan={3}>
            <Input
              id="patientBirthdateDate"
              type="text"
              isRequired={false}
              focusBorderColor="blue.200"
              value={bdState.date ?? ""}
              paddingX={{ base: 3, md: 4 }}
              onChange={(e) => {
                const value = e.target.value.slice(0, 2);
                const hankakuData = zenkakuToHankaku(value);
                setBdState({ path: "date", value: hankakuData });
              }}
            />
            <InputRightAddon
              bg={useColorModeValue("gray.50", "gray.800")}
              color={useColorModeValue("gray.500", "gray.50")}
              rounded="md"
            >
              <Text fontSize="xs">日</Text>
            </InputRightAddon>
          </InputGroup>
        </SimpleGrid>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </GridItem>
  );
};
